@import "./fade.animation.css";
@import "./probe-source-target-header.css";
@import "./hamburger.css";
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-toastify/dist/ReactToastify.css';

body {
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@tailwind base;
@tailwind components;

.code {
  font-family: "Source Code Pro", monospace;
  padding-top: 1px;
  
  @apply text-sm border rounded-md border-gray-400 px-1 inline-block;
}

.MuiButton-root.MuiButton-containedPrimary .MuiButton-label {
  color: #fff;
}

.no-border .MuiInputBase-root {
  border:none;
}

.lnrouter-container {
  @apply container mx-auto px-2 lg:px-4;
}

@tailwind utilities;

:root {
  --toastify-icon-color-success: #25CFD1;
}

 .Toastify__progress-bar--success {
  background: #25CFD1;
}