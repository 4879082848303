@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.switchSourceTarget:hover .hover-180-rotation {
  -webkit-animation: rotating 0.5s forwards; /* Safari 4+ */
  -moz-animation: rotating 0.5s forwards; /* Fx 5+ */
  -o-animation: rotating 0.5s forwards; /* Opera 12+ */
  animation: rotating 0.5s forwards; /* IE 10+, Fx 29+ */
}

